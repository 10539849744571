import axios from 'axios';

const api = axios.create({
  baseURL: 'http://127.0.0.1:5000/auth',
});

export const handleLogin = async (username, password) => {
  // TODO: Remove this
  if (username === 'teacher' && password === 'mock') {
    const mockedData = {
      name: 'Mocked teacher',
      role: 'TEACHER',
      username: 'teacher',
    };
    localStorage.setItem('user', JSON.stringify(mockedData));

    return mockedData;
  }

  const data = await api.post('/login', { username, password });

  if (data.status === 200) {
    localStorage.setItem('user', JSON.stringify(data.data));

    return true;
  }

  return false;
};
