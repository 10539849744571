import React from 'react';
import './Dialogs.css';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { CancelButton, DeleteButton, AddButton } from '../Buttons/Buttons';

const DeleteDialog = React.forwardRef(({ closeDialog, confirmDelete }, ref) => {
  return (
    <dialog ref={ref} className="custom-dialog">
      <div className="dialog-content">
        <div className="icon-container">
          <IoIosCloseCircleOutline className="close-icon" />
        </div>
        <h2>Confirmação de exclusão</h2>
        <p>Tem certeza que deseja excluir este usuário?</p>
        <div className="dialog-buttons">
          <DeleteButton onClick={confirmDelete} />
          <CancelButton onClick={closeDialog} />
        </div>
      </div>
    </dialog>
  );
});

const AddDialog = React.forwardRef(({ closeDialog, add }, ref) => {
  return (
    <dialog ref={ref} className="custom-dialog">
      <div className="dialog-content">
        <h2>Adicionar um novo usuário</h2>
        <p>Digite abaixo o login / RA do usuário que deseja adicionar</p>
        <div className="user-input-form">
          <input type="text" className="user-input" placeholder="Login / RA" />
        </div>
        <div className="dialog-buttons">
          <AddButton onClick={add} />
          <CancelButton onClick={closeDialog} />
        </div>
      </div>
    </dialog>
  );
});

export { DeleteDialog, AddDialog };
