import axios from 'axios';

const api = axios.create({
  baseURL: 'http://127.0.0.1:5000/classes',
});

export const handleGetUserClasses = async (userId) => {
  const { data } = await api.get(`/${userId}`);

  return data;
};

export const handleDeleteClasse = async (classId) => {
  await api.delete(`/${classId}`);
};
