import React, { useState } from 'react';
import './Assignments.css';
import { CiClock1 } from 'react-icons/ci';
import { useLocation } from 'react-router-dom';
import SideBarMenu from '../Sidebar/Sidebar';
import UserList from '../UserList/UserList';
import Cards from '../Cards/Cards';
import LongMenu from '../Cards/Menu';
import { handleDeleteAssignment } from '../services/assingments';

const UserAssignments = () => {
  const location = useLocation();
  const { assignments } = location.state || {};
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const isTeacher = storedUser.role === 'TEACHER';

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <SideBarMenu />
      <div className="main-content">
        {isTeacher ? (
          <Tabs assignments={assignments} isTeacher={isTeacher} />
        ) : (
          <Cards
            cardElements={assignments}
            isTeacher={isTeacher}
            Header={getHeader}
            Content={getCardContent}
          />
        )}
      </div>
    </div>
  );
};

const Tabs = ({ assignments, isTeacher }) => {
  const [activeTab, setActiveTab] = useState('tarefas');
  const teachers = ['Sophia Anderson', 'James Montgomery', 'Emily Davis'];

  const tutors = [
    'Olivia Johnson',
    'Michael Thompson',
    'Emma Williams',
    'Henry Adams',
  ];

  const students = [
    'Liam Harris',
    'Isabella Clark',
    'Ethan Brown',
    'Mia Lewis',
    'Noah Wilson',
  ];

  const renderContent = () => {
    if (activeTab === 'tarefas') {
      return (
        <Cards
          cardElements={assignments}
          isTeacher={isTeacher}
          Header={getHeader}
          Content={getCardContent}
        />
      );
    }

    return (
      <>
        <UserList title={'Professores'} users={teachers} />
        <UserList title={'Monitores'} users={tutors} />
        <UserList title={'Alunos'} users={students} />
      </>
    );
  };

  return (
    <div>
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === 'tarefas' ? 'active' : ''}`}
          onClick={() => setActiveTab('tarefas')}
        >
          Tarefas
        </button>
        <button
          className={`tab-button ${activeTab === 'pessoas' ? 'active' : ''}`}
          onClick={() => setActiveTab('pessoas')}
        >
          Pessoas
        </button>
      </div>

      <div className="tab-content">{renderContent()}</div>
    </div>
  );
};

const getHeader = ({ element: assignment, isTeacher }) => {
  const { DateTime } = require('luxon');
  const gmtDate = DateTime.fromHTTP('Fri, 09 Aug 2024 00:00:00 GMT', {
    zone: 'utc',
  });
  const localDate = gmtDate.setZone('America/Sao_Paulo');
  const timeZoneDate = localDate.plus({ hours: 3 });
  const formattedDate = timeZoneDate.toFormat('dd/MM/yyyy');

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <h2>{assignment.title}</h2>
        {isTeacher && (
          <LongMenu onDelete={() => handleDeleteAssignment(assignment.id)} />
        )}
      </div>
      <div className="assignment-time">
        <CiClock1 />
        <span>{formattedDate} 23:59</span>
      </div>
    </>
  );
};

const getCardContent = ({ isTeacher, element: assignment }) => {
  if (isTeacher) {
    return (
      <>
        <label>Status: {assignment.status}</label>
        <label>
          {assignment.delivered} / {assignment.total_assignees} entregas
        </label>
      </>
    );
  }

  return (
    <>
      <label>{assignment.total_submissions} submissões feitas</label>
      <label>
        {assignment.total_success_cases} / {assignment.total_tests} testes
        aprovados
      </label>
    </>
  );
};

export default UserAssignments;
