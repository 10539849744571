import React, { useRef, useState } from 'react';
import './UserList.css';
import { MdDelete } from 'react-icons/md';
import { SmallCreateButton } from '../Buttons/Buttons';
import { AddDialog, DeleteDialog } from '../Dialogs/Dialogs';

const UserList = ({ title, users }) => {
  const deleteDialogRef = useRef(null);
  const addDialogRef = useRef(null);
  const [userList, setUserList] = useState(users);
  const [selectedUser, setSelectedUser] = useState(null);

  const openDeleteDialog = (user) => {
    setSelectedUser(user);
    deleteDialogRef.current.showModal();
  };

  const closeDeleteDialog = () => {
    deleteDialogRef.current.close();
  };

  const openAddDialog = () => {
    addDialogRef.current.showModal();
  };

  const closeAddDialog = () => {
    addDialogRef.current.close();
  };

  const confirmDelete = () => {
    setUserList(userList.filter((user) => user !== selectedUser));
    closeDeleteDialog();
  };

  return (
    <>
      <div className="list-header">
        <h2 className="list-title">
          {title} ({userList.length})
        </h2>
        <SmallCreateButton onClick={openAddDialog} />
      </div>

      <div className="users">
        {userList &&
          userList.map((user, index) => (
            <div className="user" key={index}>
              <label>{user}</label>
              <MdDelete
                onClick={() => openDeleteDialog(user)}
                className="delete-icon"
              />
            </div>
          ))}
      </div>

      <DeleteDialog
        ref={deleteDialogRef}
        closeDialog={closeDeleteDialog}
        confirmDelete={confirmDelete}
      />

      <AddDialog
        ref={addDialogRef}
        closeDialog={closeAddDialog}
        add={closeAddDialog}
      />
    </>
  );
};

export default UserList;
