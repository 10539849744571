import React from 'react';
import './Cards.css';
import { AccessButton, CreateButton } from '../Buttons/Buttons';
import LongMenu from './Menu';

const Cards = ({ cardElements, isTeacher, accessAction, Header, Content }) => {
  return (
    <div className="cards">
      {cardElements &&
        cardElements.map((element) => (
          <div key={element.id} className="card">
            <div className="header">
              <Header element={element} />
            </div>

            <div className="status">
              {Content && <Content isTeacher={isTeacher} element={element} />}
            </div>
            <AccessButton onClick={() => accessAction(element)} />
          </div>
        ))}
      {isTeacher && <CreateButton onClick={() => {}} />}
    </div>
  );
};

export default Cards;
