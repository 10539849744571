import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SideBarMenu from '../Sidebar/Sidebar';
import Cards from '../Cards/Cards';
import { handleGetUserClasses, handleDeleteClasse } from '../services/classess';
import LongMenu from '../Cards/Menu';

const UserClasses = () => {
  const [classes, setData] = useState([]);
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const isTeacher = storedUser.role === 'TEACHER';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await handleGetUserClasses(storedUser.username);
        setData(data);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchData();
  }, [storedUser.username]);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <SideBarMenu />
      <div className="main-content">
        <Classes classes={classes} isTeacher={isTeacher} />
      </div>
    </div>
  );
};

const Classes = ({ classes, isTeacher }) => {
  const navigate = useNavigate();
  const handleClick = (classe) => {
    navigate('/assignments', { state: { assignments: classe.assignments } });
  };

  return (
    <>
      <Cards
        cardElements={classes}
        isTeacher={isTeacher}
        accessAction={handleClick}
        Header={getHeader}
        // Content={getCardContent}
      />
    </>
  );
};

const getHeader = ({ element: classes, isTeacher }) => {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <h2>{classes.name}</h2>
        {isTeacher && (
          <LongMenu onDelete={() => handleDeleteClasse(classes.id)} />
        )}
      </div>
    </>
  );
};

// const getCardContent = ({ element: classes }) => {
//   return (
//     <>
//       <div className="status">{classes.class}</div>
//     </>
//   );
// };

export default UserClasses;
