import React, { useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { AccessButton } from '../Buttons/Buttons';
import { handleLogin } from '../services/login';

const Login = () => {
  const navigate = useNavigate();

  // Mock
  const classes = [
    {
      subject: 'MC102 - 2s2024',
      class: 'Turma Y',
    },
    {
      subject: 'MC202 - 1s2025',
      class: 'Turma A',
    },
  ];

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isLoginSuccessful = await handleLogin(username, password);

    if (isLoginSuccessful) {
      navigate('/classes', { state: { classes } });
    } else {
      setMessage('Login failed');
    }
  };

  return (
    <div className="login-container">
      <h1>SuSsy</h1>
      <h2>Sistema de submissões da Unicamp</h2>
      <div className="form">
        <div>
          <label htmlFor="ra">RA</label>
          <input
            type="text"
            id="ra"
            className="login-input"
            name="ra"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">Senha</label>
          <input
            type="password"
            className="login-input"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <AccessButton onClick={handleSubmit} />
        {message && <p>{message}</p>}
        {/*TODO: Por estilo nisso...*/}
      </div>
    </div>
  );
};

export default Login;
