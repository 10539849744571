import React from 'react';
import './App.css';
import Login from './Login/Login';
import UserAssignments from './Assignments/Assignments';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UserClasses from './Classes/Classes';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/classes" element={<UserClasses />} />
        <Route path="/assignments" element={<UserAssignments />} />
      </Routes>
    </Router>
  );
}

export default App;
